import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Clock } from "../../../assets/icon/clock.svg";
import { ReactComponent as Phone } from "../../../assets/icon/phone-alt.svg";
import { ReactComponent as Pin } from "../../../assets/icon/pin.svg";
import { ReactComponent as Wp1 } from "../../../assets/icon/whatsapp.svg";
import { ReactComponent as X } from "../../../assets/icon/x.svg";
import { ReactComponent as Linkedin } from "../../../assets/icon/linkedin3.svg";
import { ReactComponent as Insta } from "../../../assets/icon/insta4.svg";
import { ReactComponent as FB } from "../../../assets/icon/Fb5.svg";
import { ReactComponent as YT } from "../../../assets/icon/yt.svg";
import { usePageLayoutQuery } from "../../../services/pageService";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LanguageActions } from "redux/languageSlice";
import axiosInstance from "config/interceptor";
import en from "config/locales/en";
import { useTranslate } from "hooks/useTranslate";

const HeaderTop = () => {
  const translate = useTranslate();

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector((store) => store.language.lang);
  const { data } = usePageLayoutQuery();
  const location = useLocation()

  const [active, setActive] = useState("");
  const [callResponseMessage, setCallResponseMessage] = useState({
    error: "",
    success: "",
  });
  const handleClick = () => {
    setActive(active === "" ? "active" : "");
    setCallResponseMessage({
      error: "",
      success: "",
    });
  };

  const changeLanguage = (lang) => {
    localStorage.setItem("kaan-lang", lang);
    // i18n.changeLanguage(lang);
    dispatch(LanguageActions.changeLanguage(lang));
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const completeCallOrder = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const body = Object.fromEntries(formData);
    try {
      const response = await axiosInstance.post(
        "call-order",
        {
          ...body,
        },
        {
          params: { ...body },
        }
      );
      setCallResponseMessage({
        error: "",
        success: `${translate('tesekkurler')}`,
      });
    } catch (e) {
      setCallResponseMessage({
        error: `${translate('xeta-bas-verdi')}`,
        success: "",
      });
    }
  };
  const [value, setValue] = useState("");
  const handleKeyPress = (event) => {
    // Allow only numbers to be entered
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9]/gi.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="headerLeft">
            <ul className="headerMenu">
              <li>
                <Link to="/about">{translate("haqqimizda")}</Link>
              </li>
              <li>
                <Link to="/brands">{translate("brendler")}</Link>
              </li>
              <li>
                <Link to="/video">{translate("videolar")}</Link>
              </li>
              <li>
                <Link to="/blog">{translate("blog")}</Link>
              </li>
              <li>
                <Link to="/support">{translate("destek")}</Link>
              </li>
              <li>
                <Link to="/contact">{translate("elaqe")}</Link>
              </li>
            </ul>
          </div>
          <div className="headerRight">
            <div>
              <a href={`tel:${data?.setting?.tel_short}`} className="phoneNumber">
                <Phone />
                {data?.setting?.tel_short}
              </a>
            </div>
            <div>
              <a
                target={"_blank"}
                href={`https://wa.me/${data?.setting?.tel_whatsapp}`}
                className="whatsapp"
              >
                <Wp1 />
              </a>
            </div>
            <div>
              <button type="button" className="callOrder" onClick={handleClick}>
                {translate("zeng-sifarisi")}
              </button>
              <div className={`addNewAddr ${active}`}>
                <div>
                  <button
                    type="button"
                    className="modalClose"
                    onClick={handleClick}
                  >
                    <X />
                  </button>
                  <h4>{translate("zeng-sifarisi")}</h4>
                  {!callResponseMessage.error &&
                    !callResponseMessage.success && (
                      <form onSubmit={completeCallOrder}>
                        <label htmlFor="">{translate("ad-soyad")}</label>
                        <div className="inputGroup">
                          <input
                            required={true}
                            type="text"
                            name={"name_surname"}
                            placeholder={`${translate("ad-soyad")}`}
                          />
                        </div>

                        <label htmlFor="">{translate("mobil-nomre")}</label>
                        <div className="inputGroup contactPhone">
                          <input
                            type="text"
                            id="tel"
                            required={true}
                            name="tel"
                            maxLength="10"
                            onChange={(event) => setValue(event.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                          <input
                            type="text"
                            value="+994"
                            disabled

                          />
                        </div>
                        <button type={"submit"}>
                          {translate("sifaris-et")}
                        </button>
                      </form>
                    )}

                  {(callResponseMessage.error ||
                    callResponseMessage.success) && (
                    <div className="alert-body">
                      <h3>
                        {callResponseMessage.success ||
                          callResponseMessage.error}
                      </h3>
                      <button
                        onClick={handleClick}
                        className={`${
                          callResponseMessage.success ? "success" : "error"
                        }`}
                      >
                        {translate("bagla")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="modalBg" onClick={handleClick}></div>
            </div>
            <div className="headerAddress">
              <span>
                <Pin />
                {data?.setting?.address}
              </span>
              <span>
                <Clock />
                {data?.setting?.work_hours}
              </span>
            </div>
            <div className="lang">
              <Link
                onClick={() => changeLanguage("az")}
                className={`${lang === "az" ? "selected-lang" : ""}`}
                to={{
                  pathname: location.pathname,
                    search: location.search,
                  hash: location.hash,
                }}
              >
                Az
              </Link>
              <Link
                onClick={() => changeLanguage("en")}
                className={`${lang === "en" ? "selected-lang" : ""}`}
                to={{
                  pathname: location.pathname,
                  search: location.search,
                  hash: location.hash,
                }}
              >
                En
              </Link>
              <Link
                onClick={() => changeLanguage("ru")}
                className={`${lang === "ru" ? "selected-lang" : ""}`}
                to={{
                  pathname: location.pathname,
                  search: location.search,
                  hash: location.hash,
                }}
              >
                Py
              </Link>
            </div>
            <div className="headerSocials">
              {/* <!-- insta --> */}
              <Link to={data?.setting?.instagram} target="_blank">
                <Insta/>
              </Link>

              {/* <!-- youtube --> */}
              <Link to={data?.setting?.youtube} target="_blank">
                <YT/>
              </Link>

              {/* <!-- facebook --> */}
              <Link to={data?.setting?.facebook} target="_blank">
              <FB/>
              </Link>

              {/* <!-- tiktok --> */}
              <Link to={data?.setting?.tiktok} target="_blank">
                <Linkedin />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
