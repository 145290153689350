import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import az from "./locales/az";
import store from "redux/store";

const resources = {
    // en: {translation: {}},
    // az: {translation: {}}
}
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("kaan-lang") ?? "az", // if you're using a language detector, do not define the lng option
        fallbackLng: "az",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        partialBundledLanguages: true,
        // detection : {
        //     order : ["htmlTag" , "cookie", "localStorage" , "sessionStorage"],
        //     caches : ["cookie"]
        // },
    });
