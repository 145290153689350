import api from "./api";

const cartService = api.injectEndpoints({
  endpoints: (build) => ({
    getCart: build.query({
      query: () => ({
        url : "/cart/list"
      }),
      providesTags: ["Cart"],
    }),
    addToCart: build.mutation({
      query: (id) => ({
        url: `/cart/add/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
    plusToCart: build.mutation({
      query: (id) => ({
        url: `/cart/plus/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
    minusToCart: build.mutation({
      query: (id) => ({
        url: `/cart/minus/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
    deleteFromCart: build.mutation({
      query: (id) => ({
        url: `/cart/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
    emptyCart: build.mutation({
      query: () => ({
        url: `/cart/empty`,
        method: "POST",
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useGetCartQuery,
  useAddToCartMutation,
  usePlusToCartMutation,
  useMinusToCartMutation,
  useDeleteFromCartMutation,
  useEmptyCartMutation,
} = cartService;
