import {createApi} from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";

const axiosBaseQuery = () =>
    async ({url, method, data, params}) => {
        try {
            const result = await axios({url :"https://api.wheel-size.com/v2"+ url+"?user_key=6f87bceff356a3385e7e1b9e12850569", method, data, params})
            return {data: result.data}
        } catch (axiosError) {
            let err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }
export const carServiceApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: "kaan.az/carFilter",
    endpoints: (builder) => ({
        getMakes : builder.query({
                query: () => ({
                    url : "/makes/"
                }),
        }),
        getModels : builder.query({
            query: (make) => ({
                url : "/models/",
                params : {
                    make
                }
            }),
        }),
        getYears : builder.query({
            query: (data) => ({
                url : "/years/",
                params : {
                    ...data
                }
            }),
        }),
        getGenerations : builder.query({
            query: (data) => ({
                url : "/generations/",
                params : {
                   ...data
                }
            }),
        }),
        getModifications : builder.query({
            query: (data) => ({
                url : "/modifications/",
                params : {
                    ...data
                }
            }),
        }),
        getSearchByModel : builder.query({
            query: (data) => ({
                url : "/search/by_model/",
                params : {
                    ...data
                }
            }),
        })
    }),
});

export const {
    useGetMakesQuery,
    useGetModelsQuery,
    useGetYearsQuery,
    useGetGenerationsQuery,
    useGetModificationsQuery,
    useGetSearchByModelQuery
} = carServiceApi;
