import React, { useState } from "react";
import {Link, useLocation} from "react-router-dom";
import { ReactComponent as Clock2 } from "../../../assets/icon/clock2.svg";
import { ReactComponent as Fb2 } from "../../../assets/icon/fb2.svg";
import { ReactComponent as Insta2 } from "../../../assets/icon/insta2.svg";
import { ReactComponent as Phone } from "../../../assets/icon/phone.svg";
import { ReactComponent as Pin2 } from "../../../assets/icon/pin2.svg";
import { ReactComponent as Linkedin2 } from "../../../assets/icon/linkedin2.svg";
import { ReactComponent as Wp } from "../../../assets/icon/wp.svg";
import { ReactComponent as Yt2 } from "../../../assets/icon/youtube2.svg";
import { ReactComponent as Logo2 } from "../../../assets/icon/Logo2.svg";
import { usePageLayoutQuery } from "../../../services/pageService";
import { ReactComponent as X } from "../../../assets/icon/x.svg";
import axiosInstance from "config/interceptor";
import {useTranslate} from "hooks/useTranslate";

const FooterContact = () => {
  const translate = useTranslate();
  const {pathname} = useLocation();
  const { data } = usePageLayoutQuery();
  const [active, setActive] = useState("");
  const [callResponseMessage, setCallResponseMessage] = useState({
    error: "",
    success: "",
  });
  const handleClick = () => {
    setActive(active === "" ? "active" : "");
    setCallResponseMessage({
      error: "",
      success: "",
    });
  };
  const completeCallOrder = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const body = Object.fromEntries(formData);

    try {
      const response = await axiosInstance.post(
        "call-order",
        {
          ...body,
        },
        {
          params: { ...body },
        }
      );
      setCallResponseMessage({
        error: "",
        success: `${translate('tesekkurler')}`,
      });
    } catch (e) {
      setCallResponseMessage({
        error: `${translate('xeta-bas-verdi')}`,
        success: "",
      });
    }
  };
  const [value, setValue] = useState("");
  const handleKeyPress = (event) => {
    // Allow only numbers to be entered
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9]/gi.test(keyValue)) {
      event.preventDefault();
    }
  };
  return (
    <div className="container">
      <div className="footerContact">
        <div className="footerLogo">
          <Link to={'/'} style={{
            pointerEvents : pathname === "/" ? 'none' : 'auto'
          }}>
            {/* <img src={data?.setting?.logo} alt="" /> */}
            <Logo2 />
          </Link>
        </div>
        <div className="footerPhone">
          <a href={`tel:${data?.setting?.tel_short}`} className="phoneNumber">
            <Phone />
            <span>{data?.setting?.tel_short}</span>
          </a>
        </div>
        <div>
          <Link className="callOrder" onClick={handleClick}>
            {translate('zeng-sifarisi')}
          </Link>
          <div className={`addNewAddr ${active}`}>
            <div>
              <button
                type="button"
                className="modalClose"
                onClick={handleClick}
              >
                <X />
              </button>
              <h4> {translate('zeng-sifarisi')}</h4>
              {!callResponseMessage.error && !callResponseMessage.success && (
                <form onSubmit={completeCallOrder}>
                  <label htmlFor="">{translate("ad-soyad")}</label>
                  <div className="inputGroup">
                    <input
                      required={true}
                      type="text"
                      name={"name_surname"}
                      placeholder={`${translate("ad-soyad")}`}
                    />
                  </div>

                  <label htmlFor="">{translate('mobil-nomre')}</label>
                  <div className="inputGroup contactPhone">
                    <input
                      type="text"
                      id="tel"
                      required={true}
                      name="tel"
                      maxLength="10"
                      onChange={(event) => setValue(event.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <input type="text" value="+994" disabled />
                  </div>
                  <button type={"submit"}>{translate('sifaris-et')}</button>
                </form>
              )}

              {(callResponseMessage.error || callResponseMessage.success) && (
                <div className="alert-body">
                  <h3>
                    {callResponseMessage.success || callResponseMessage.error}
                  </h3>
                  <button
                    onClick={handleClick}
                    className={`${
                      callResponseMessage.success ? "success" : "error"
                    }`}
                  >
                    {translate('bagla')}
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="modalBg" onClick={handleClick}></div>
        </div>
        <div className="headerAddress">
          <span>
            <Pin2 />
            {data?.setting?.address}
          </span>
          <span>
            <Clock2 />
            {data?.setting?.work_hours}
          </span>
        </div>
        <div className="footerSocials">
          {/* <!-- whatsapp --> */}
          <a
            target={"_blank"}
            href={`https://wa.me/${data?.setting?.tel_whatsapp}`}
          >
            <Wp />
          </a>

          {/* <!-- instagram --> */}
          <Link to={data?.setting?.instagram}>
            <Insta2 />
          </Link>

          {/* <!-- youtube --> */}
          <Link to={data?.setting?.youtube}>
            <Yt2 />
          </Link>

          {/* <!-- facebook --> */}
          <Link to={data?.setting?.facebook}>
            <Fb2 />
          </Link>

          {/* <!-- tiktok --> */}
          <Link to={data?.setting?.tiktok}>
            <Linkedin2 />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
