import {useGetTranslationQuery} from "services/languageService";

export const useTranslate = () => {

    const {data} = useGetTranslationQuery();

    const translate = (key) => {
        if (!data)
            return
        return data[key] ? data[key] : key
    }

    return translate
}
