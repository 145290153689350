import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from "hooks/useTranslate";

const Copyright = () => {
    const translate = useTranslate();
    const year = new Date().getFullYear()
  return (
    <div className="copyright">
          <div className="container">
              <p>{year} @ {translate("all-rights-reserved")} - KAAN ŞİRKƏTİ VÖEN:1300144431</p>
              <div>
                  <span>Made by:</span>
                  <Link to='' target="_blank">One STUDIO</Link>
              </div>
          </div>
      </div>
  )
}

export default Copyright