import {createApi,} from "@reduxjs/toolkit/query/react";
import axiosInstance from '../config/interceptor';

const axiosBaseQuery = () =>
    async ({url, method, data, params}) => {
        try {
            const result = await axiosInstance({url : url, method, data, params})
            return {data: result.data}
        } catch (axiosError) {
            let err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

const api = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: "kaan.az",
    endpoints: () => ({}),
    tagTypes: ["Wishlist", "Cart"],
});

export default api;
