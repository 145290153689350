import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ReactComponent as HeartIcon} from "../../../assets/icon/Heart.svg";
import {ReactComponent as BasketIcon} from "../../../assets/icon/cart.svg";
import {ReactComponent as Clock} from "../../../assets/icon/clock.svg";
import {ReactComponent as Left} from "../../../assets/icon/left.svg";
import {ReactComponent as Phone} from "../../../assets/icon/phone-alt.svg";
import {ReactComponent as Pin} from "../../../assets/icon/pin.svg";
import {ReactComponent as ProfileIcon} from "../../../assets/icon/profile.svg";
import {ReactComponent as RightIcon} from "../../../assets/icon/right.svg";
import {ReactComponent as Wp1} from "../../../assets/icon/whatsapp.svg";
import {ReactComponent as Logo} from "../../../assets/icon/Logo.svg";
import {ReactComponent as Call} from "../../../assets/icon/call.svg";
import {ReactComponent as WA} from "../../../assets/icon/wa.svg";
import { ReactComponent as Linkedin } from "../../../assets/icon/linkedin3.svg";
import { ReactComponent as Insta } from "../../../assets/icon/insta4.svg";
import { ReactComponent as X } from "../../../assets/icon/x.svg";
import {ReactComponent as Check} from "../../../assets/icon/tick-circle.svg";
import {usePageLayoutQuery, useSmartSearchQuery,} from "../../../services/pageService";
import useDebounce from "../../../hooks/useDebounce";
import {useTranslate} from "hooks/useTranslate";
import {useGetCartQuery} from "../../../services/cartService";
import {LanguageActions} from "../../../redux/languageSlice";
import {useDispatch, useSelector} from "react-redux";
import axiosInstance from "config/interceptor";

const HeaderBottom = () => {
    const dispatch = useDispatch();
    const lang = useSelector((store) => store.language.lang);
    const {pathname} = useLocation();
    const basket = useGetCartQuery();
    const translate = useTranslate();
    const [searchParams] = useSearchParams()
    const defaultSearch = searchParams.get("search_text") || "";

    const [search, setSearch] = useState(defaultSearch);
    const [active, setActive] = useState("");
    const [active2, setActive2] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const {data} = usePageLayoutQuery();

    const navigate = useNavigate();

    const debounceValue = useDebounce(search, 500);

    const result = useSmartSearchQuery({search_text: debounceValue});

    const [callActive, setCallActive] = useState("");
  const [callResponseMessage, setCallResponseMessage] = useState({
    error: "",
    success: "",
  });
  const handleCallClick = () => {
    setCallActive(callActive === "" ? "active" : "");
    setCallResponseMessage({
      error: "",
      success: "",
    });
  };

  const completeCallOrder = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const body = Object.fromEntries(formData);
    try {
      const response = await axiosInstance.post(
        "call-order",
        {
          ...body,
        },
        {
          params: { ...body },
        }
      );
      setCallResponseMessage({
        error: "",
        success: `${translate('tesekkurler')}`,
      });
    } catch (e) {
      setCallResponseMessage({
        error: `${translate('xeta-bas-verdi')}`,
        success: "",
      });
    }
  };
  const [value, setValue] = useState("");
  const handleKeyPress = (event) => {
    // Allow only numbers to be entered
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[^0-9]/gi.test(keyValue)) {
      event.preventDefault();
    }
  };

    const handleClick = () => {
        setActive(active === "" ? "active" : "");
        if (document.body.classList.contains("active")) {
            document.body.classList.remove("active");
        } else {
            document.body.classList.add("active");
        }
    };
    const handleClick2 = () => {
        setActive2(active2 === "" ? "active" : "");
        document.body.classList.remove("active2");

    };
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const handleSubMenuClick = () => {
        setIsSubMenuOpen(true);
    };

    const handleSubMenuItemClick = () => {
        setIsSubMenuOpen(false);
    };

    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // const handleSearchClick = () => {
    //     setIsSubMenuOpen(true);
    // };

    const handleSearchItemClick = () => {
        setIsSearchOpen(false);
        document.body.classList.remove("active2");
    };


    function handleFocus() {
        setIsFocused(true);
        setIsSearchOpen(true);
        document.body.classList.add("active2");
    }

    const handleSearchResults = () => {
        setIsFocused(false);
        document.body.classList.remove("active2");
    }

    const changeLanguage = (lang) => {
        localStorage.setItem("kaan-lang", lang);
        // i18n.changeLanguage(lang);
        dispatch(LanguageActions.changeLanguage(lang));
        setTimeout(() => {
            window.location.reload();
        }, 300);
    };

    const searchRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsFocused(false);
                document.body.classList.remove("active2");
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);

    const inputRef = useRef(null);

    const handleSearch = (e) => {
        e.preventDefault();
        setIsFocused(false)
        navigate(`/products?search_text=${search}&page=1`);
        inputRef.current.blur();
        document.body.classList.remove("active2");
    }

    return (
        <>
            <div>
                <div className="container">
                    <Link to="/" className="logo" style={{
                        pointerEvents : pathname === "/" ? 'none' : 'auto'
                    }}>
                        <Logo/>
                    </Link>
                    <div className="menuTop">
                        <Link to="/basket" className="basketLink">
                            <BasketIcon/>
                            <span>
                            {basket?.data?.cart?.count ? basket?.data?.cart?.count : 0}
                            </span>
                        </Link>
                        <Link to="/login">
                            <ProfileIcon/>
                        </Link>
                    
                    </div>
                    <div className="categories">
                        <div className="mobileMenuTop">
                            <div>
                                {/* <!-- basket --> */}
                                <Link to="/">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.5 2.75C1.78472 2.75 1.88356 2.75061 1.96771 2.75749C2.81866 2.8271 3.55689 3.37234 3.87367 4.16519C3.905 4.2436 3.93465 4.33789 4.01838 4.61002L5.97994 10.9851L5.99316 11.0281L5.99317 11.0281C6.29595 12.0122 6.54213 12.8123 6.81597 13.4358C7.10167 14.0863 7.44679 14.6169 7.98784 15.0165C8.52889 15.4161 9.13745 15.5899 9.84321 15.6717C10.5197 15.75 11.3569 15.75 12.3865 15.75H12.4314H14.5358H14.581C15.6178 15.75 16.4607 15.75 17.1414 15.6708C17.8515 15.5882 18.4635 15.4125 19.0064 15.0086C19.5493 14.6047 19.8934 14.069 20.1766 13.4126C20.4481 12.7833 20.6902 11.976 20.9881 10.9829L21.0011 10.9396L21.1736 10.3649L21.1909 10.3072L21.1909 10.3071C21.5299 9.17727 21.8081 8.24984 21.918 7.5053C22.0325 6.72976 21.9907 5.99345 21.5182 5.35845C21.0458 4.72344 20.3525 4.47185 19.5768 4.35863C18.8321 4.24995 17.8638 4.24997 16.6842 4.25L16.6239 4.25H5.5C5.49234 4.25 5.48471 4.25012 5.47711 4.25035L5.45205 4.16889L5.44242 4.13757C5.37195 3.90849 5.32362 3.75133 5.26661 3.60865C4.73864 2.28724 3.50825 1.3785 2.09 1.26249C1.93686 1.24996 1.77244 1.24998 1.53276 1.25L1.53276 1.25L1.5 1.25H1C0.585786 1.25 0.25 1.58579 0.25 2C0.25 2.41422 0.585786 2.75 1 2.75H1.5ZM8.75 19.9999C8.75 19.3096 9.30964 18.7499 10 18.7499C10.6904 18.7499 11.25 19.3096 11.25 19.9999C11.25 20.6903 10.6904 21.2499 10 21.2499C9.30964 21.2499 8.75 20.6903 8.75 19.9999ZM10 17.2499C8.48122 17.2499 7.25 18.4812 7.25 19.9999C7.25 21.5187 8.48122 22.7499 10 22.7499C11.5188 22.7499 12.75 21.5187 12.75 19.9999C12.75 18.4812 11.5188 17.2499 10 17.2499ZM16.75 19.9999C16.75 19.3096 17.3096 18.7499 18 18.7499C18.6904 18.7499 19.25 19.3096 19.25 19.9999C19.25 20.6903 18.6904 21.2499 18 21.2499C17.3096 21.2499 16.75 20.6903 16.75 19.9999ZM18 17.2499C16.4812 17.2499 15.25 18.4812 15.25 19.9999C15.25 21.5187 16.4812 22.7499 18 22.7499C19.5188 22.7499 20.75 21.5187 20.75 19.9999C20.75 18.4812 19.5188 17.2499 18 17.2499Z"
                                            fill="#A7ABB5"
                                        />
                                    </svg>
                                    {basket?.data?.cart?.count ? basket?.data?.cart?.count : 0}
                                </Link>
                                {/* <!-- favorites --> */}
                                <Link to="/">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.5103 5.92642C9.36501 3.73237 5.8348 3.73237 3.6895 5.92642C1.61131 8.05184 1.61131 11.448 3.6895 13.5734L10.7887 20.8339C11.4532 21.5135 12.5466 21.5135 13.2111 20.8339L20.3103 13.5734C22.3885 11.448 22.3885 8.05184 20.3103 5.92642C18.165 3.73237 14.6348 3.73237 12.4895 5.92642L11.9999 6.42715L11.5103 5.92642Z"
                                            fill="#A7ABB5"
                                        />
                                    </svg>
                                </Link>
                                <Link to="/">
                                    <svg
                                        width="16"
                                        height="20"
                                        viewBox="0 0 16 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M7.75 0C5.12665 0 3 2.12665 3 4.75C3 7.37335 5.12665 9.5 7.75 9.5C10.3734 9.5 12.5 7.37335 12.5 4.75C12.5 2.12665 10.3734 0 7.75 0ZM4.75 12C2.12665 12 0 14.1266 0 16.75C0 18.2688 1.23122 19.5 2.75 19.5H12.75C14.2688 19.5 15.5 18.2688 15.5 16.75C15.5 14.1266 13.3734 12 10.75 12H4.75Z"
                                            fill="#A7ABB5"
                                        />
                                    </svg>
                                </Link>
                                <div className="mobileLang">
                                    <Link
                                        onClick={() => changeLanguage("az")}
                                        className={`${lang === "az" ? "selected-lang" : ""}`}
                                    >
                                        Az
                                    </Link>
                                    <Link
                                        onClick={() => changeLanguage("en")}
                                        className={`${lang === "en" ? "selected-lang" : ""}`}
                                    >
                                        En
                                    </Link>
                                    <Link
                                        onClick={() => changeLanguage("ru")}
                                        className={`${lang === "ru" ? "selected-lang" : ""}`}
                                    >
                                        Py
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <ul className="categoryMenu">
                            {data?.categories?.map((item) => (
                                <li key={item.id}>
                                    <Link
                                        to={

                                            // item.sub_categories.length > 0
                                            //     ? ""
                                            //     :
                                            `/products?cat_slug=${item.slug}&page=1`

                                        }
                                        onMouseEnter={handleSubMenuClick}
                                        onClick={handleSubMenuItemClick}
                                    >
                                        {item.name}
                                        <svg
                                            width="28"
                                            height="28"
                                            viewBox="0 0 28 28"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M10.9599 6.29314C11.3504 5.90261 11.9836 5.90261 12.3741 6.29314L19.3741 13.2931C19.7646 13.6837 19.7646 14.3168 19.3741 14.7074L12.3741 21.7074C11.9836 22.0979 11.3504 22.0979 10.9599 21.7074C10.5694 21.3168 10.5694 20.6837 10.9599 20.2931L17.2528 14.0002L10.9599 7.70735C10.5694 7.31683 10.5694 6.68366 10.9599 6.29314Z"
                                                fill="#0D8359"
                                            />
                                        </svg>
                                    </Link>
                                    {item.sub_categories.length > 0 && (
                                        <>
                                    {isSubMenuOpen && (
                                    <div className="categorySubMenu" id="tireMenu">
                                        <div className="container">
                                            {item.sub_categories.map((subItem, subIndex) => (
                                                <>
                                                    <Link key={subIndex} to="" className="subMenuClose">
                                                        <svg
                                                            width="28"
                                                            height="28"
                                                            viewBox="0 0 28 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M17.0401 21.7074C16.6496 22.0979 16.0164 22.0979 15.6259 21.7074L8.6259 14.7074C8.23538 14.3168 8.23538 13.6837 8.6259 13.2931L15.6259 6.29314C16.0164 5.90261 16.6496 5.90261 17.0401 6.29314C17.4306 6.68366 17.4306 7.31683 17.0401 7.70735L10.7472 14.0002L17.0401 20.2931C17.4306 20.6837 17.4306 21.3168 17.0401 21.7074Z"
                                                                fill="#0D8359"
                                                            />
                                                        </svg>
                                                        {subItem.name}
                                                    </Link>

                                                    <ul key={subItem}>
                                                        <li>
                                                            <Link to={`/products?cat_slug=${subItem.slug}&page=1`}
                                                                  onClick={handleSubMenuItemClick}>
                                                                {subItem.name}
                                                            </Link>
                                                        </li>
                                                        {subItem.sub_categories.map((subSubItem, subIndex) => (
                                                            <li key={subIndex}>
                                                                <Link
                                                                    to={`/products?cat_slug=${subSubItem.slug}&page=1`}
                                                                    onClick={handleSubMenuItemClick}
                                                                >
                                                                    {subSubItem.name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ))}

                                            <div>
                                                <Link to="" className="menuSeeAll" onClick={handleSubMenuItemClick}>
                                                    {/* <img src={SubMenuImg} alt="" /> */}
                                                    <div className="blogBg"></div>
                                                    <div className="blogHover">
                                                        <span>{translate('hamisina-bax')}</span>
                                                        <svg
                                                            width="53"
                                                            height="12"
                                                            viewBox="0 0 53 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M52.165 6.4983C52.4402 6.22309 52.4402 5.77691 52.165 5.5017L47.6803 1.01704C47.4051 0.741838 46.9589 0.741838 46.6837 1.01704C46.4085 1.29224 46.4085 1.73843 46.6837 2.01363L50.6701 6L46.6837 9.98637C46.4085 10.2616 46.4085 10.7078 46.6837 10.983C46.9589 11.2582 47.4051 11.2582 47.6803 10.983L52.165 6.4983ZM0 6.7047H51.6667V5.2953H0V6.7047Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>)}
                                    </>
                                    )}

                                </li>
                            ))}
                            <Link to="/campaigns" className="campaignsLink">
                                <span>%</span> {translate('kampaniyalar')}
                            </Link>
                        </ul>
                    </div>
                    <div className={`search ${active2}`} ref={searchRef}>
                        <form action="" onSubmit={handleSearch}>
                            <div className="inputGroup" >
                                <input
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    type="text"
                                    placeholder={`${translate('axtaris-et')}`}
                                    onFocus={handleFocus}
                                    // onBlur={handleFocus}
                                    className={`${isFocused ? "active" : ""}`}
                                    ref={inputRef}
                                />
                                <button>
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                            stroke="#91A8A7"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M20.9999 21L16.6499 16.65"
                                            stroke="#91A8A7"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>

                            </div>
                        </form>
                        <div>
                            <button type="button" className="backBtn" onClick={handleClick2}>
                                {translate('geri')}
                            </button>
                        </div>
                        {isSearchOpen && (
                            <div className={`searchResults ${isFocused ? "active" : ""}`}>
                            {
                                 <div className="container">
                                    <div>
                                        {
                                            (result?.data?.length === 0 && search) &&
                                            <p>{translate('netice-tapilmadi')}</p>
                                        }
                                        {result?.data?.length > 0 && result?.data?.map((item) => (
                                            <Link
                                                key={item.id}
                                                to={`/products/${item.slug}&page=1`}
                                                className="searchResult"F
                                                onClick={handleSearchItemClick}

                                            >
                                                <div className="imgContainer">
                                                    <img src={item.image} alt=""/>
                                                </div>
                                                <span
                                                    className="searchResultName">{item.brand?.brand_name} {item.name} </span>
                                                <div className="searchPrice">
                                                    {item.old_price !== 0 && (
                                                        <span className="searchOldPrice">
                            {item.old_price}
                                                            <svg
                                                                width="16"
                                                                height="12"
                                                                viewBox="0 0 16 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                              <g clipPath="url(#clip0_18_12841)">
                                <path
                                    d="M8.37837 2.29131L8.34866 1.10229L7.41326 1.52732L7.38592 2.29556C3.7394 2.6122 0.864258 6.20154 0.864258 10.5846C0.864258 10.8673 0.876143 11.1467 0.899915 11.4219H2.17643C2.16455 11.22 2.15623 11.0171 2.15623 10.812C2.15623 6.8359 4.43946 3.57276 7.35264 3.22317L7.09234 10.6909L8.5733 10.0533L8.40095 3.22424C11.3082 3.58126 13.5843 6.84121 13.5843 10.812C13.5843 11.0171 13.576 11.22 13.5641 11.4219H14.9179C14.9416 11.1467 14.9535 10.8673 14.9535 10.5846C14.9559 6.17923 12.051 2.57607 8.37837 2.29131Z"
                                    fill="#707070"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_18_12841">
                                  <rect
                                      width="15.1355"
                                      height="11.3516"
                                      fill="white"
                                      transform="translate(0.342773 0.586304)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                                                    )}
                                                    <span className="searchCurrentPrice">
                          {item.price}
                                                        <svg
                                                            width="26"
                                                            height="19"
                                                            viewBox="0 0 16 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                            <g clipPath="url(#clip0_18_12841)">
                              <path
                                  d="M8.37837 2.29131L8.34866 1.10229L7.41326 1.52732L7.38592 2.29556C3.7394 2.6122 0.864258 6.20154 0.864258 10.5846C0.864258 10.8673 0.876143 11.1467 0.899915 11.4219H2.17643C2.16455 11.22 2.15623 11.0171 2.15623 10.812C2.15623 6.8359 4.43946 3.57276 7.35264 3.22317L7.09234 10.6909L8.5733 10.0533L8.40095 3.22424C11.3082 3.58126 13.5843 6.84121 13.5843 10.812C13.5843 11.0171 13.576 11.22 13.5641 11.4219H14.9179C14.9416 11.1467 14.9535 10.8673 14.9535 10.5846C14.9559 6.17923 12.051 2.57607 8.37837 2.29131Z"
                                  fill="#0D8359"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18_12841">
                                <rect
                                    width="15.1355"
                                    height="11.3516"
                                    fill="white"
                                    transform="translate(0.342773 0.586304)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                    {result?.data?.length > 0 &&
                                        <Link to={`/products?search_text=${search}&page=1`} className="seeAll"
                                              onClick={handleSearchResults}>
                                            <span>{translate('hamisina-bax')}</span>
                                            <svg
                                                width="30"
                                                height="9"
                                                viewBox="0 0 30 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M29.3536 4.85453C29.5488 4.65927 29.5488 4.34269 29.3536 4.14742L26.1716 0.965443C25.9763 0.77018 25.6597 0.77018 25.4645 0.965443C25.2692 1.1607 25.2692 1.47729 25.4645 1.67255L28.2929 4.50098L25.4645 7.3294C25.2692 7.52467 25.2692 7.84125 25.4645 8.03651C25.6597 8.23177 25.9763 8.23177 26.1716 8.03651L29.3536 4.85453ZM0 5.00098H29V4.00098H0V5.00098Z"
                                                    fill="#FF2F49"
                                                />
                                            </svg>
                                        </Link>}
                                </div>
                            }
                        </div>
                        )}
                        
                    </div>
                    <div className="headerBottomRight">
                        <Link to="/basket" className="basketLink">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.5 2.75C1.78472 2.75 1.88356 2.75061 1.96771 2.75749C2.81866 2.8271 3.55689 3.37234 3.87367 4.16519C3.905 4.24359 3.93465 4.33789 4.01838 4.61001L5.97994 10.9851L5.99316 11.028L5.99317 11.0281C6.29595 12.0122 6.54213 12.8123 6.81597 13.4358C7.10167 14.0863 7.44679 14.6169 7.98784 15.0165C8.52889 15.4161 9.13745 15.5899 9.84321 15.6717C10.5197 15.75 11.3569 15.75 12.3865 15.75H12.4314H14.5358H14.581C15.6178 15.75 16.4607 15.75 17.1414 15.6708C17.8515 15.5882 18.4635 15.4125 19.0064 15.0086C19.5493 14.6047 19.8934 14.069 20.1766 13.4126C20.4481 12.7833 20.6902 11.976 20.9881 10.9829L21.0011 10.9396L21.1736 10.3649L21.1909 10.3072L21.1909 10.3071C21.5299 9.17727 21.8081 8.24983 21.918 7.5053C22.0325 6.72975 21.9907 5.99344 21.5182 5.35844C21.0458 4.72344 20.3525 4.47184 19.5768 4.35863C18.8321 4.24994 17.8638 4.24997 16.6842 4.25L16.6239 4.25H5.5C5.49234 4.25 5.48471 4.25011 5.47711 4.25034L5.45205 4.16888L5.44242 4.13757C5.37195 3.90848 5.32362 3.75133 5.26661 3.60864C4.73864 2.28723 3.50825 1.37849 2.09 1.26248C1.93686 1.24996 1.77244 1.24997 1.53276 1.25L1.53276 1.25L1.5 1.25H1C0.585786 1.25 0.25 1.58579 0.25 2C0.25 2.41421 0.585786 2.75 1 2.75H1.5ZM8.75 19.9999C8.75 19.3096 9.30964 18.7499 10 18.7499C10.6904 18.7499 11.25 19.3096 11.25 19.9999C11.25 20.6903 10.6904 21.2499 10 21.2499C9.30964 21.2499 8.75 20.6903 8.75 19.9999ZM10 17.2499C8.48122 17.2499 7.25 18.4812 7.25 19.9999C7.25 21.5187 8.48122 22.7499 10 22.7499C11.5188 22.7499 12.75 21.5187 12.75 19.9999C12.75 18.4812 11.5188 17.2499 10 17.2499ZM16.75 19.9999C16.75 19.3096 17.3096 18.7499 18 18.7499C18.6904 18.7499 19.25 19.3096 19.25 19.9999C19.25 20.6903 18.6904 21.2499 18 21.2499C17.3096 21.2499 16.75 20.6903 16.75 19.9999ZM18 17.2499C16.4812 17.2499 15.25 18.4812 15.25 19.9999C15.25 21.5187 16.4812 22.7499 18 22.7499C19.5188 22.7499 20.75 21.5187 20.75 19.9999C20.75 18.4812 19.5188 17.2499 18 17.2499Z"
                                    fill="#A7ABB5"
                                />
                            </svg>
                            <span>
                            {basket?.data?.cart?.count ? basket?.data?.cart?.count : 0}
                            </span>
                        </Link>
                        <Link to="/profile/favorites">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.5106 5.92642C9.36526 3.73237 5.83504 3.73237 3.68975 5.92642C1.61156 8.05184 1.61155 11.448 3.68974 13.5734L10.7889 20.8339C11.4534 21.5135 12.5469 21.5135 13.2114 20.8339L20.3106 13.5734C22.3887 11.448 22.3887 8.05184 20.3106 5.92642C18.1653 3.73237 14.635 3.73237 12.4897 5.92642L12.0001 6.42715L11.5106 5.92642Z"
                                    fill="#A7ABB5"
                                />
                            </svg>
                        </Link>
                        <Link to="/login">
                            <svg
                                width="16"
                                height="20"
                                viewBox="0 0 16 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.75 0C5.12665 0 3 2.12665 3 4.75C3 7.37335 5.12665 9.5 7.75 9.5C10.3734 9.5 12.5 7.37335 12.5 4.75C12.5 2.12665 10.3734 0 7.75 0ZM4.75 12C2.12665 12 0 14.1266 0 16.75C0 18.2688 1.23122 19.5 2.75 19.5H12.75C14.2688 19.5 15.5 18.2688 15.5 16.75C15.5 14.1266 13.3734 12 10.75 12H4.75Z"
                                    fill="#A7ABB5"
                                />
                            </svg>
                        </Link>
                    </div>
                    <div className="headerButtons">
                        <div className={`searchBtn ${active}`}>
                            <button type="button" onClick={handleClick2}>
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.9167 20.5833C16.7031 20.5833 20.5833 16.7031 20.5833 11.9167C20.5833 7.1302 16.7031 3.25 11.9167 3.25C7.1302 3.25 3.25 7.1302 3.25 11.9167C3.25 16.7031 7.1302 20.5833 11.9167 20.5833Z"
                                        stroke="#060606"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M22.7501 22.7501L18.0376 18.0376"
                                        stroke="#060606"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="menuBtn">
                            <button
                                type="button"
                                className={`${active}`}
                                onClick={handleClick}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`menu ${active}`}>
                <div className="container">
                    <div className="menuTop">
                        <Link to="/basket" className="basketLink" onClick={handleClick}>
                            <BasketIcon/>
                            <span>
                            {basket?.data?.cart?.count ? basket?.data?.cart?.count : 0}
                            </span>
                        </Link>
                        <Link to="/profile/favorites" onClick={handleClick}>
                            <HeartIcon/>
                        </Link>
                        <Link to="/login" onClick={handleClick}>
                            <ProfileIcon/>
                        </Link>
                        <div className="menuLang">
                            <Link
                                onClick={() => changeLanguage("az")}
                                className={`${lang === "az" ? "selected-lang" : ""}`}
                            >
                                Az
                            </Link>
                            <Link
                                onClick={() => changeLanguage("en")}
                                className={`${lang === "en" ? "selected-lang" : ""}`}
                            >
                                En
                            </Link>
                            <Link
                                onClick={() => changeLanguage("ru")}
                                className={`${lang === "ru" ? "selected-lang" : ""}`}
                            >
                                Py
                            </Link>
                        </div>
                    </div>
                    <div className="menuMid">

                        <ul>

                            <>
                                {data?.categories?.map((item) => (
                                    <>
                                        <li key={item.id}>
                                           <Link to={`/products?cat_slug=${item.slug}&page=1`} onClick={item.sub_categories && item.sub_categories.length ? '' : handleClick}> {item.name}</Link>
                                            {
                                                (item.sub_categories && item.sub_categories.length > 0) && <>
                                                    <RightIcon/>
                                                    <ul className="menuSubCategory">
                                                        {/*<Left/>*/}
                                                        <li> {item.name}</li>
                                                        {
                                                            item.sub_categories.map((subItem, subIndex) => {
                                                                return <>
                                                                    <li key={subIndex}>
                                                                        <Link style={{
                                                                            fontWeight : "bold",

                                                                        }} to={`/products?cat_slug=${subItem.slug}&page=1`} onClick={handleClick}>
                                                                            {subItem.name}</Link>
                                                                    </li>
                                                                    {
                                                                        subItem.sub_categories.map((sub_item_cat , sub_item_index)=>{
                                                                           return  <li key={sub_item_index}>
                                                                               <Link to={`/products?cat_slug=${sub_item_cat.slug}&page=1`} onClick={handleClick}>
                                                                                   {sub_item_cat.name}</Link>
                                                                           </li>
                                                                        })
                                                                    }
                                                                </>
                                                            })
                                                        }
                                                    </ul>
                                                </>
                                            }
                                            {/*{isSubMenuOpen && (<div className="categorySubMenu" id="tireMenu">*/}
                                            {/*    <div className="container">*/}
                                            {/*        {item.sub_categories.map((subItem, subIndex) => (*/}
                                            {/*            <>*/}
                                            {/*                <Link key={subIndex} to="" className="subMenuClose">*/}
                                            {/*                    <svg*/}
                                            {/*                        width="28"*/}
                                            {/*                        height="28"*/}
                                            {/*                        viewBox="0 0 28 28"*/}
                                            {/*                        fill="none"*/}
                                            {/*                        xmlns="http://www.w3.org/2000/svg"*/}
                                            {/*                    >*/}
                                            {/*                        <path*/}
                                            {/*                            fillRule="evenodd"*/}
                                            {/*                            clipRule="evenodd"*/}
                                            {/*                            d="M17.0401 21.7074C16.6496 22.0979 16.0164 22.0979 15.6259 21.7074L8.6259 14.7074C8.23538 14.3168 8.23538 13.6837 8.6259 13.2931L15.6259 6.29314C16.0164 5.90261 16.6496 5.90261 17.0401 6.29314C17.4306 6.68366 17.4306 7.31683 17.0401 7.70735L10.7472 14.0002L17.0401 20.2931C17.4306 20.6837 17.4306 21.3168 17.0401 21.7074Z"*/}
                                            {/*                            fill="#0D8359"*/}
                                            {/*                        />*/}
                                            {/*                    </svg>*/}
                                            {/*                    {subItem.name}*/}
                                            {/*                </Link>*/}

                                            {/*                <ul key={subItem}>*/}
                                            {/*                    <li>*/}
                                            {/*                        <Link to={`/products?cat_slug=${subItem.slug}`}*/}
                                            {/*                              onClick={handleSubMenuItemClick}>*/}
                                            {/*                            {subItem.name}*/}
                                            {/*                        </Link>*/}
                                            {/*                    </li>*/}
                                            {/*                    {subItem.sub_categories.map((subSubItem, subIndex) => (*/}
                                            {/*                        <li key={subIndex}>*/}
                                            {/*                            <Link*/}
                                            {/*                                to={`/products?cat_slug=${subSubItem.slug}`}*/}
                                            {/*                                onClick={handleSubMenuItemClick}*/}
                                            {/*                            >*/}
                                            {/*                                {subSubItem.name}*/}
                                            {/*                            </Link>*/}
                                            {/*                        </li>*/}
                                            {/*                    ))}*/}
                                            {/*                </ul>*/}
                                            {/*            </>*/}
                                            {/*        ))}*/}

                                            {/*        <div>*/}
                                            {/*            <Link to="" className="menuSeeAll" onClick={handleSubMenuItemClick}>*/}
                                            {/*                /!* <img src={SubMenuImg} alt="" /> *!/*/}
                                            {/*                <div className="blogBg"></div>*/}
                                            {/*                <div className="blogHover">*/}
                                            {/*                    <span>{translate('hamisina-bax')}</span>*/}
                                            {/*                    <svg*/}
                                            {/*                        width="53"*/}
                                            {/*                        height="12"*/}
                                            {/*                        viewBox="0 0 53 12"*/}
                                            {/*                        fill="none"*/}
                                            {/*                        xmlns="http://www.w3.org/2000/svg"*/}
                                            {/*                    >*/}
                                            {/*                        <path*/}
                                            {/*                            d="M52.165 6.4983C52.4402 6.22309 52.4402 5.77691 52.165 5.5017L47.6803 1.01704C47.4051 0.741838 46.9589 0.741838 46.6837 1.01704C46.4085 1.29224 46.4085 1.73843 46.6837 2.01363L50.6701 6L46.6837 9.98637C46.4085 10.2616 46.4085 10.7078 46.6837 10.983C46.9589 11.2582 47.4051 11.2582 47.6803 10.983L52.165 6.4983ZM0 6.7047H51.6667V5.2953H0V6.7047Z"*/}
                                            {/*                            fill="white"*/}
                                            {/*                        />*/}
                                            {/*                    </svg>*/}
                                            {/*                </div>*/}
                                            {/*            </Link>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>)}*/}

                                        </li>
                                        <button className={`menuBack ${active2}`}>
                                            <Left/>
                                        </button>
                                    </>
                                ))}
                                <Link to="/campaigns" className="campaignsLink" onClick={handleClick}>
                                    <span>%</span> {translate('kampaniyalar')}
                                </Link>
                            </>


                            {/*<li>*/}
                            {/*    Təkər*/}
                            {/*    <RightIcon/>*/}
                            {/*    <ul className="menuSubCategory">*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">Təkər</Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <Link to="/products">*/}
                            {/*                Yük avtomobilləri və avtobus təkərləri*/}
                            {/*            </Link>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            <button className={`menuBack ${active2}`}>
                                <Left/>
                            </button>
                            {/* map e buttonla bir yerde girecek subcategory olanlarda */}
                          <div className="menuLinks">
                          <li>
                                <Link to="/about" onClick={handleClick}>{translate('haqqimizda')}</Link>{" "}
                            </li>
                            <li>
                                <Link to="/brands" onClick={handleClick}>{translate('brendler')}</Link>{" "}
                            </li>
                            <li>
                                <Link to="/video" onClick={handleClick}>{translate("Video")}</Link>{" "}
                            </li>
                            <li>
                                <Link to="/blog" onClick={handleClick}>{translate("blog")}</Link>
                            </li>
                            <li>
                                <Link to="/support" onClick={handleClick}>{translate("destek")}</Link>{" "}
                            </li>
                            <li>
                                <Link to="/contact" onClick={handleClick}>{translate("elaqe")}</Link>{" "}
                            </li>
                          </div>
                        </ul>
                    </div>
                    <div className="menuFooter">
                        <div className="headerRight">
                            <div>
                            <a href={`tel:${data?.setting?.tel_short}`} className="phoneNumber">
                <Phone />
                {data?.setting?.tel_short}
              </a>
                            </div>
                            <div>
                            <a
                target={"_blank"}
                href={`https://wa.me/${data?.setting?.tel_whatsapp}`}
                className="whatsapp"
              >
                                    <Wp1/>
                                </a>
                            </div>
                            <div>
                                <Link to="" className="callOrder" onClick={handleCallClick}>
                                    Zəng sifarişi
                                </Link>
                            </div>
                            
                            <div className="headerAddress">
                <span>
                  <Pin/>
                  Babək pr. 2222, Bakı
                </span>
                                <span>
                  <Clock/>
                  9:00 – 18:00
                </span>
                            </div>
                            <div className="lang">
                            <Link
                onClick={() => changeLanguage("az")}
                className={`${lang === "az" ? "selected-lang" : ""}`}
              >
                Az
              </Link>
              <Link
                onClick={() => changeLanguage("en")}
                className={`${lang === "en" ? "selected-lang" : ""}`}
              >
                En
              </Link>
              <Link
                onClick={() => changeLanguage("ru")}
                className={`${lang === "ru" ? "selected-lang" : ""}`}
              >
                Py
              </Link>
                            </div>
                            <div className="headerSocials">
                                {/* <!-- insta --> */}
                                <Link to={data?.setting?.instagram} target="_blank">
                                    <Insta/>
                                </Link>

                                {/* <!-- youtube --> */}
                                <Link to={data?.setting?.youtube} target="_blank">
                                    <svg
                                        width="19"
                                        height="13"
                                        viewBox="0 0 19 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M18.2727 2.14865C18.0655 1.36853 17.455 0.754132 16.68 0.545626C15.2751 0.166748 9.64169 0.166748 9.64169 0.166748C9.64169 0.166748 4.00833 0.166748 2.60342 0.545626C1.82834 0.754165 1.2179 1.36853 1.01071 2.14865C0.634277 3.56267 0.634277 6.51288 0.634277 6.51288C0.634277 6.51288 0.634277 9.46309 1.01071 10.8771C1.2179 11.6572 1.82834 12.246 2.60342 12.4545C4.00833 12.8334 9.64169 12.8334 9.64169 12.8334C9.64169 12.8334 15.2751 12.8334 16.68 12.4545C17.455 12.246 18.0655 11.6572 18.2727 10.8771C18.6491 9.46309 18.6491 6.51288 18.6491 6.51288C18.6491 6.51288 18.6491 3.56267 18.2727 2.14865ZM7.79926 9.19145V3.83431L12.5077 6.51295L7.79926 9.19145Z"
                                            fill="#A7ABB6"
                                        />
                                    </svg>
                                </Link>

                                {/* <!-- facebook --> */}
                                <Link to={data?.setting?.facebook} target="_blank">
                                    <svg
                                        width="11"
                                        height="19"
                                        viewBox="0 0 11 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.1582 10.6875L10.6859 7.24895H7.3865V5.01756C7.3865 4.07684 7.8474 3.15986 9.3251 3.15986H10.8251V0.232305C10.8251 0.232305 9.46389 0 8.16246 0C5.44531 0 3.66926 1.64691 3.66926 4.62828V7.24895H0.648926V10.6875H3.66926V19H7.3865V10.6875H10.1582Z"
                                            fill="#A7ABB6"
                                        />
                                    </svg>
                                </Link>

                                {/* <!-- linkedin --> */}
                                <Link to={data?.setting?.tiktok} target="_blank">
                                   <Linkedin />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="popup">
            <Check/>
            Məhsul səbətə əlavə olundu
        </div> */}
        <div className="call">
            <a href={`tel:${data?.setting?.tel_short}`}>
                <Call/>
                {translate('zeng-et')}
            </a>
            <a target={"_blank"}
                href={`https://wa.me/${data?.setting?.tel_whatsapp}`}>
                <WA/>
            </a>
        </div>
        <div className={`addNewAddr ${callActive}`} style={{zIndex: 30}}>
                <div>
                  <button
                    type="button"
                    className="modalClose"
                    onClick={handleCallClick}
                  >
                    <X />
                  </button>
                  <h4>{translate("zeng-sifarisi")}</h4>
                  {!callResponseMessage.error &&
                    !callResponseMessage.success && (
                      <form onSubmit={completeCallOrder}>
                        <label htmlFor="">{translate("ad-soyad")}</label>
                        <div className="inputGroup">
                          <input
                            required={true}
                            type="text"
                            name={"name_surname"}
                            placeholder={`${translate("ad-soyad")}`}
                          />
                        </div>

                        <label htmlFor="">{translate("mobil-nomre")}</label>
                        <div className="inputGroup contactPhone">
                          <input
                            type="text"
                            id="tel"
                            required={true}
                            name="tel"
                            maxLength="10"
                            onChange={(event) => setValue(event.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                          <input
                            type="text"
                            value="+994"
                            disabled

                          />
                        </div>
                        <button type={"submit"}>
                          {translate("sifaris-et")}
                        </button>
                      </form>
                    )}

                  {(callResponseMessage.error ||
                    callResponseMessage.success) && (
                    <div className="alert-body">
                      <h3>
                        {callResponseMessage.success ||
                          callResponseMessage.error}
                      </h3>
                      <button
                        onClick={handleCallClick}
                        className={`${
                          callResponseMessage.success ? "success" : "error"
                        }`}
                      >
                        {translate("bagla")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
        </>
    );
};

export default HeaderBottom;
