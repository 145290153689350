import React from "react";
import FooterContact from "../FooterContact";
import FooterContent from "../FooterContent";
import Copyright from "../Copyright";

const Footer = () => {
  return (
    <footer>
      <FooterContact />
      <FooterContent />
      <Copyright />
    </footer>
  );
};

export default Footer;
