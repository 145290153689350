import React, {lazy, Suspense, useEffect} from "react";
import "./config/i18"
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import "./index.css";
import {useGetHomePageQuery} from "./services/pageService";
import Loader from "./components/common/Loader";
import {Toaster} from "react-hot-toast";
import {useSelector} from "react-redux";
import Layout from "./components/Layout";

const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const VideosPage = lazy(() => import("./pages/VideosPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const BlogPage = lazy(() => import("./pages/BlogPage"));
const BlogDetailsPage = lazy(() => import("./pages/BlogDetailsPage"));
const CampaignsPage = lazy(() => import("./pages/CampaignsPage"));
const CampaignDetailPage = lazy(() => import("./pages/CampaignDetailPage"))
const BrandsPage = lazy(() => import("./pages/BrandsPage"));
const SupportPage = lazy(() => import("./pages/SupportPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const ProductPage = lazy(() => import("./pages/ProductPage"));
const ProductDetailsPage = lazy(() => import("./pages/ProductDetailsPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const FavoritesPage = lazy(() => import("./pages/FavoritesPage"));
const OrdersPage = lazy(() => import("./pages/OrdersPage"));
const ChangePasswordPage = lazy(() => import("./pages/ChangePasswordPage"));
const BasketPage = lazy(() => import("./pages/BasketPage"));
const OrderSuccessPage = lazy(() => import("./pages/OrderSuccessPage"));
const OrderErrorPage = lazy(() => import("./pages/OrderErrorPage"));
const SitemapXml = lazy(() => import('./pages/SiteMapXml'));

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

const App = ({hideHeaderPaths = ["/login", "/error", "/sitemap.xml", "/en/sitemap.xml", "/ru/sitemap.xml"]}) => {
    const {lang} = useSelector(state => state.language);
    const location = useLocation();
    const {pathname} = location;
    const result = useGetHomePageQuery();
    const navigate = useNavigate()

    useEffect(() => {

        window.scrollTo(0, 0);
        const langRegex = /\/(en|ru)/;

        // if locale is az, and pathname starts with /en, drop /en from pathname
        const locale = pathname.match(langRegex)?.[1] ?? "az";

        if (lang === 'az' && locale !== lang) {
            // az is default, so if locale is az, drop it from pathname
            const newPath = pathname.replace(langRegex, "");
            return navigate(newPath + location.search)
        } else if (locale !== lang) {
            console.log(pathname.split('/')[1])
            if (!langRegex.test(pathname.split('/')[1])) {
                return navigate(lang + pathname.replace(langRegex, "") + location.search)
            }
            return navigate(pathname.replace(langRegex, `/${lang}`) + location.search)
        }

    }, [pathname]);

    if (result.isFetching) {
        return <Loader/>
    }


    return (
        <div className="App">
            <Toaster containerStyle={{
                fontSize: "18px"
            }}/>
            {!hideHeaderPaths.includes(pathname) && <Header/>}
            <Outlet/>
            <Suspense fallback={<div>Loading...</div>}>
                <Layout>
                    <Routes>
                        {/*    routes can be start with /en, /ru and default / is az */}
                        <Route path="/en">
                            <Route exact path="*" element={<Navigate to="/error"/>}/>
                            <Route exact path="/en/error" element={<ErrorPage/>}/>
                            <Route exact path="/en/" element={<HomePage/>}/>
                            <Route exact path="/en/login" element={<LoginPage/>}/>
                            <Route exact path="/en/about" element={<AboutPage/>}/>
                            <Route exact path="/en/video" element={<VideosPage/>}/>
                            <Route exact path="/en/contact" element={<ContactPage/>}/>
                            <Route exact path="/en/blog" element={<BlogPage/>}/>
                            <Route exact path="/en/blog/:id" element={<BlogDetailsPage/>}/>
                            <Route exact path="/en/campaigns" element={<CampaignsPage/>}/>
                            <Route exact path="/en/campaign/:id" element={<CampaignDetailPage/>}/>
                            <Route exact path="/en/brands" element={<BrandsPage/>}/>
                            <Route exact path="/en/support" element={<SupportPage/>}/>
                            <Route exact path="/en/products" element={<ProductPage/>}/>
                            <Route exact path="/en/products/:id" element={<ProductDetailsPage/>}/>
                            <Route exact path="/en/profile/profile-info" element={<ProfilePage/>}/>
                            <Route exact path="/en/profile/favorites" element={<FavoritesPage/>}/>
                            <Route exact path="/en/profile/orders" element={<OrdersPage/>}/>
                            <Route exact path="/en/profile/change-password" element={<ChangePasswordPage/>}/>
                            <Route exact path="/en/basket" element={<BasketPage/>}/>
                            <Route exact path="/en/success_payment" element={<OrderSuccessPage/>}/>
                            <Route exact path="/en/error_payment" element={<OrderErrorPage/>}/>
                            <Route exact path="/en/sitemap.xml" element={<SitemapXml/>}/>
                        </Route>
                        <Route path="/ru">
                            <Route exact path="*" element={<Navigate to="/error"/>}/>
                            <Route exact path="/ru/error" element={<ErrorPage/>}/>
                            <Route exact path="/ru/" element={<HomePage/>}/>
                            <Route exact path="/ru/login" element={<LoginPage/>}/>
                            <Route exact path="/ru/about" element={<AboutPage/>}/>
                            <Route exact path="/ru/video" element={<VideosPage/>}/>
                            <Route exact path="/ru/contact" element={<ContactPage/>}/>
                            <Route exact path="/ru/blog" element={<BlogPage/>}/>
                            <Route exact path="/ru/blog/:id" element={<BlogDetailsPage/>}/>
                            <Route exact path="/ru/campaigns" element={<CampaignsPage/>}/>
                            <Route exact path="/ru/campaign/:id" element={<CampaignDetailPage/>}/>
                            <Route exact path="/ru/brands" element={<BrandsPage/>}/>
                            <Route exact path="/ru/support" element={<SupportPage/>}/>
                            <Route exact path="/ru/products" element={<ProductPage/>}/>
                            <Route exact path="/ru/products/:id" element={<ProductDetailsPage/>}/>
                            <Route exact path="/ru/profile/profile-info" element={<ProfilePage/>}/>
                            <Route exact path="/ru/profile/favorites" element={<FavoritesPage/>}/>
                            <Route exact path="/ru/profile/orders" element={<OrdersPage/>}/>
                            <Route exact path="/ru/profile/change-password" element={<ChangePasswordPage/>}/>
                            <Route exact path="/ru/basket" element={<BasketPage/>}/>
                            <Route exact path="/ru/success_payment" element={<OrderSuccessPage/>}/>
                            <Route exact path="/ru/error_payment" element={<OrderErrorPage/>}/>
                            <Route exact path="/ru/sitemap.xml" element={<SitemapXml/>}/>
                        </Route>
                        <Route path="/">
                            <Route exact path="*" element={<Navigate to="/error"/>}/>
                            <Route exact path="/error" element={<ErrorPage/>}/>
                            <Route exact path="/" element={<HomePage/>}/>
                            <Route exact path="/login" element={<LoginPage/>}/>
                            <Route exact path="/about" element={<AboutPage/>}/>
                            <Route exact path="/video" element={<VideosPage/>}/>
                            <Route exact path="/contact" element={<ContactPage/>}/>
                            <Route exact path="/blog" element={<BlogPage/>}/>
                            <Route exact path="/blog/:id" element={<BlogDetailsPage/>}/>
                            <Route exact path="/campaigns" element={<CampaignsPage/>}/>
                            <Route exact path="/campaign/:id" element={<CampaignDetailPage/>}/>
                            <Route exact path="/brands" element={<BrandsPage/>}/>
                            <Route exact path="/support" element={<SupportPage/>}/>
                            <Route exact path="/products" element={<ProductPage/>}/>
                            <Route exact path="/products/:id" element={<ProductDetailsPage/>}/>
                            <Route exact path="/profile/profile-info" element={<ProfilePage/>}/>
                            <Route exact path="/profile/favorites" element={<FavoritesPage/>}/>
                            <Route exact path="/profile/orders" element={<OrdersPage/>}/>
                            <Route exact path="/profile/change-password" element={<ChangePasswordPage/>}/>
                            <Route exact path="/basket" element={<BasketPage/>}/>
                            <Route exact path="/success_payment" element={<OrderSuccessPage/>}/>
                            <Route exact path="/error_payment" element={<OrderErrorPage/>}/>
                            <Route exact path="/sitemap.xml" element={<SitemapXml/>}/>
                        </Route>
                    </Routes>
                </Layout>
                <ScrollToTop/>
            </Suspense>
            {!hideHeaderPaths.includes(pathname) && <Footer/>}
        </div>
    );
};

export default App;
