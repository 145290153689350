import api from "./api";

const languageService = api.injectEndpoints({
    endpoints: (build) => ({
        getTranslation: build.query({
            query: () => ({
                url: "/translation-list",
                method: 'GET'
            }),
            transformResponse: (response) => {
                const translationObject = {};
                response.forEach(item => {
                    translationObject[item.key] = item.value
                })
                return translationObject
            }
        }),
    }),
});

export const {
    useGetTranslationQuery
} = languageService;
