import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/dist/query";
import {persistReducer, persistStore} from "redux-persist";
import {encryptTransform} from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import api from "../services/api";
import {carServiceApi} from "../services/carService";
import language from "./languageSlice"
import auth from "./authSlice";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
    transforms: [
        encryptTransform({
            secretKey: "kaan_persist_key", // AES256 encryption key
            onError: function (error) {
                // Handle the error.
            },
        }),
    ],
};

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [carServiceApi.reducerPath] : carServiceApi.reducer,
    auth,
    language,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware).concat(carServiceApi.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
export default store;
