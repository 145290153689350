import {useEffect, useState} from "react";

const useDebounce = (text, delay) => {

    const [value, setValue] = useState(text);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setValue(text);
        }, delay);
        return ()=> clearTimeout(timeout);
    }, [text])

    return value

}
export default useDebounce;
