import React from "react";
import store, {persistor} from "./redux/store";
import {HelmetProvider} from "react-helmet-async";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import "./config/interceptor";
import {createRoot} from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(<BrowserRouter>
    <>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <HelmetProvider>
                    <App/>
                </HelmetProvider>
            </PersistGate>
        </Provider>
    </>
</BrowserRouter>)
