import React from "react";
import { Link } from "react-router-dom";
import { usePageLayoutQuery } from "../../../services/pageService";
import {useTranslate} from "hooks/useTranslate";

const FooterContent = () => {
  const translate = useTranslate();

  const { data } = usePageLayoutQuery();
  return (
    <div className="container">
      <div className="footerContent">
        <div className="footerLeft">
          {
            data?.categories?.map((item , index)=>{
              return   <ul style={{
              }} key={index}>

                <Link to={`/products?cat_slug=${item.slug}&page=1`}>{item.name}</Link>
                {
                  item.sub_categories?.map((y , z)=>{
                   return <ul key={z}>
                      <li>
                        <Link to={`/products?cat_slug=${y.slug}&page=1`}>
                          {y.name}
                        </Link>
                      </li>
                    </ul>
                  })
                }
              </ul>

            })
          }

        </div>
        <div className="footerRight">
          <ul className="footerMenu">
            <div>
              <li>
                <Link to="/about">{translate('haqqimizda')}</Link>
              </li>
              <li>
                <Link to="/brands">{translate('brendler')}</Link>
              </li>
            </div>
            <div>
              <li>
                <Link to="/blog">{translate('blog')}</Link>
              </li>
              <li>
                <Link to="/support">{translate('destek')}</Link>
              </li>
              <li>
                <Link to="/contact">{translate('elaqe')}</Link>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterContent;
