import axios from "axios";
import {api_url} from "config/api";
import store from "redux/store";
import AuthSlice, {AuthActions} from "redux/authSlice";

// Add a request interceptor
 const axiosInstance = axios.create({
    baseURL: api_url
});

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    const auth = store.getState().auth;
    if (auth.token) {
        config.headers.set("Authorization", `Bearer ${auth.token}`);
    } else {
        config.headers.set("guest-uuid", `${auth.guestId}`);
    }
    const selectedLanguage = store.getState().language.lang; // Dil seçimini buradan alabilirsiniz.
    config.headers.set('lang', selectedLanguage);

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    const dispatch = store.dispatch;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if(error.response.status === 401){
        dispatch(AuthActions.logout())
    }
    return Promise.reject(error);
});

export default axiosInstance
