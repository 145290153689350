import React from 'react';
import LoaderIcon from '../../assets/icon/apple-touch-icon.png'
const Loader = () => {
    return (
        <div style={{
            display : "flex",
            justifyContent : "center",
            alignItems : "center",
            position : "absolute",
            top : 0,
            left : 0,
            right : 0,
            bottom : 0,
            width : '100%',
            height : '100%'
        }}>
                <img className={'loader'} src={LoaderIcon} alt={"loader icons"}/>
        </div>
    );
};

export default Loader;
