import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axiosInstance from "config/interceptor";

export const getTranslationList = createAsyncThunk("getTranslationList",
    async (lang, thunkAPI) => {
        try {
            const response = await axiosInstance("/translation-list");
            return response.data;
        } catch (e) {

        }
    })

const languageSlice = createSlice({
    name: "language",
    initialState: {
        lang: localStorage.getItem("kaan-lang") ?? "az",
        translate: {}
    },
    reducers: {
        changeLanguage: (state, action) => {
            state.lang = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getTranslationList.fulfilled, (state, action) => {
        })
    }
});

export default languageSlice.reducer;
export const LanguageActions = languageSlice.actions
